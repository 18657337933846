export default function animationObserver() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('can-animate');
                observer.unobserve(entry.target);
            }
        });
    });

    const elementsToAnimate = document.querySelectorAll('.js-animation-observe');

    elementsToAnimate.forEach(elementToAnimate => observer.observe(elementToAnimate));
}

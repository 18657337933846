export default function arrowRotation() {
    document.addEventListener('mousemove', (event) => {
        const elements = document.querySelectorAll('.js-arrow-rotate');
        elements.forEach((item) => {
            const element = item;

            const svgRect = element.getBoundingClientRect();
            const svgCenter = {
                x: svgRect.left + svgRect.width / 2,
                y: svgRect.top + svgRect.height / 2,
            };
            const angle = Math.atan2(event.clientY - svgCenter.y, event.clientX - svgCenter.x);
            const angleDeg = angle * (180 / Math.PI);
            element.style.transform = `rotate(${angleDeg}deg)`;
        });
    });
}

export default function animateCounters() {
    function animateCounter(counterElement) {
        const element = counterElement;

        const target = Number(element.dataset.target);
        const duration = Number(element.dataset.duration);
        const suffix = element.dataset.suffix || '';
        const direction = element.dataset.direction === 'down' ? -1 : 1;

        const startValue = direction === -1 ? target : 0;
        const endValue = direction === -1 ? 0 : target;

        const startTime = Date.now();
        const endTime = startTime + duration;

        function animate() {
            const now = Date.now();
            const progress = Math.min((now - startTime) / (endTime - startTime), 1);
            const current = Math.floor(startValue + progress * (endValue - startValue));

            element.innerText = current + suffix;

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }

        animate();
    }

    const counters = document.querySelectorAll('.js-count-animation');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                animateCounter(entry.target);
                observer.unobserve(entry.target);
            }
        });
    });

    counters.forEach(counter => observer.observe(counter));
}

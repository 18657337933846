export default function animatedText() {
    function animateText(element) {
        const newElement = element;

        const originalText = newElement.textContent;
        const {newText, interval} = newElement.dataset;
        let index = 0;

        const writeNewText = () => {
            if (index <= newText.length) {
                newElement.textContent = newText.slice(0, index);
                index += 1;
                setTimeout(writeNewText, interval);
            } else {
                newElement.classList.add('animation-done');
            }
        };

        const removeOriginalText = () => {
            if (index < originalText.length) {
                newElement.textContent = originalText.slice(0, originalText.length - index - 1);
                index += 1;
                setTimeout(removeOriginalText, interval);
            } else {
                index = 0;
                writeNewText();
            }
        };

        removeOriginalText();
    }

    const elements = document.querySelectorAll('.js-animated-text');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                animateText(entry.target);
                observer.unobserve(entry.target);
            }
        });
    });

    elements.forEach(element => observer.observe(element));
}

export default function distanceText() {
    const endElement = document.querySelector('.js-distance-end');
    const numberElement = document.querySelector('.js-distance-number');

    if (!endElement && !numberElement) { return; }

    document.addEventListener('mousemove', (event) => {
        const endRect = endElement.getBoundingClientRect();
        const endCenter = {
            x: endRect.left + endRect.width / 2,
            y: endRect.top + endRect.height / 2,
        };
        const distance = Math.sqrt(
            // eslint-disable-next-line no-restricted-properties
            Math.pow(event.clientX - endCenter.x, 2) + Math.pow(event.clientY - endCenter.y, 2),
        );
        numberElement.textContent = distance.toFixed(0);
        if (event.clientX >= endRect.left && event.clientX <= endRect.right
            && event.clientY >= endRect.top && event.clientY <= endRect.bottom) {
            endElement.classList.add('mouse-over');
            numberElement.textContent = '0';
        } else {
            endElement.classList.remove('mouse-over');
        }
    });
}

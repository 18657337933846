const header = {
    init() {
        const headerElement = document.querySelector('.js-header');
        header.check(headerElement);
        window.addEventListener('scroll', () => {
            header.check(headerElement);
        });
    },
    check(headerElement) {
        if (window.scrollY >= headerElement.clientHeight) {
            headerElement.classList.add('fixed');
        } else {
            headerElement.classList.remove('fixed');
        }
    },
};

export default header;

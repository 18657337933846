const featuresTabs = {
    init() {
        const containers = document.querySelectorAll('.js-features-tab-container');
        containers.forEach((element) => {
            const tabs = element.querySelectorAll('.js-features-tab-entry');
            tabs.forEach((tab) => {
                const trigger = tab.querySelector('.js-features-tab-trigger');
                trigger.addEventListener('click', () => {
                    if (tab.classList.contains('active')) {
                        tab.classList.remove('active');
                    } else {
                        tab.classList.add('active');
                    }
                });
            });
        });
    },
};

export default featuresTabs;
